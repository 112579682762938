.taggedItem {
  padding: 0 16px;
}

.temp {
  background-color: green;
  padding: 50px;
}

.title {
  font-family: "Geared Slab";
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 20px;
  padding: 30px 16px 16px;
  margin-bottom: -20px;
}

.border {
  height: 1px;
  width: calc(100% - 32px);
  margin: 0 16px;
  margin-top: 8px;
  background-color: #e1e5ee;
}
