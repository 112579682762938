.container {
  height: 100%;
  padding: 0 16px;
}

.container:after {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  margin-top: 16px;
}

.header {
  display: flex;
  align-items: center;
  font-family: "Geared Slab";
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-top: 20px;
  margin-bottom: 20px;
  color: var(--goboat-blue);
}

.header svg {
  margin-right: 23px;
}

.imgWrapper {
  margin: 24px 0;
}

.img {
  width: calc(100vw - 32px);
  height: calc((100vw - 32px) * 0.583090379);
  border-radius: 8px;
}

.videoText {
  margin-top: 8px;
  font-size: 12px;
  font-weight: 700;
  color: var(--medium-blue);
}

.text {
  margin: 8px 0;
  font-size: 14px;
  line-height: 16px;
}

.subhead {
  font-size: 25px;
  margin-top: 12px;
  margin-bottom: 8px;
}

.btnWrapper {
  margin: 24px 0;
}

.blockContent {
  padding-bottom: 15px;
  font-size: 14px;
}

.iconBottom > p:last-child {
  position: relative;
}

.iconBottom > p:last-child::after {
  content: "";
  position: absolute;
  background-image: url("../../img/icons-for-small-tags/docker.svg");
  background-size: 14px;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
  display: inline-block;
  bottom: 0px;
  margin-left: 4px;
}

.iconBottom a {
  font-weight: 1000;
}
