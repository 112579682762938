.filter {
  display: flex;
  height: 40px;
  min-width: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: white;
  width: min-content;
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 700;
  white-space: nowrap;
  margin-left: auto;
  box-shadow: 0px 0px 20px #888;
}

.longItem {
  padding-left: 12px;
  padding-right: 16px;
  min-width: fit-content;
}

.x {
  padding-right: 9px;
}

.list {
  background-color: white;
  padding: 16px;
  display: grid;
  gap: 22px;
  width: min-content;
  white-space: nowrap;
  border-radius: 20px;
  box-shadow: 0px 0px 20px #888;
}

.hide {
  display: none;
}

.filterList {
  margin-top: 10px;
}

.option {
  display: flex;
  align-items: center;
}

.option input {
  margin-right: 10px;
}

.option button {
  padding: 0;
  font-size: 14px;
  line-height: 16px;
}

.goboat-blue {
  background-color: var(--goboat-blue);
  color: white;
}

/* export type Colors =
| "goboat-blue"
| "blue"
| "medium-blue"
| "light-blue"
| "light-grey"
| "yellow"
| "green"
| "red"
| "white"
| "pink"
| "purple" */

/* --goboat-blue: #2e3d5c;
--blue: #54a8e5;
--medium-blue: #959dad;
--light-blue: #f1f3f6;
--light-grey: #f5f5f5;
--yellow: #ffb500;
--green: #77a846;
--red: #e55457;
--white: #fff;
--pink: #de0078;
--purple: #7146a8; */
