.wrapper {
  display: block;
  position: relative;
}

.item {
  display: flex;
  align-items: center;
  position: relative;
}

/* TODO: SET MINWIDTH / MIN HEIGHT!! */
.img {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  margin-right: 16px;
  margin-bottom: 8px;
  background-color: var(--medium-blue);
}

.imgPlaceholder {
  min-width: 80px;
  width: 80px;
  height: 80px;
  border-radius: 8px;
  margin-right: 16px;
  margin-bottom: 8px;
  background-color: var(--medium-blue);
}

.content {
  display: grid;
  gap: 4px;
  border-bottom: 1px solid #e1e5ee;
  padding-bottom: 16px;
  padding-top: 8px;
}

.notStarMap {
  position: absolute;
  top: 0px;
  right: 0px;
}

.hideBorder {
  border: none;
}

.title {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100vw - 32px - 80px - 16px - 34px);
}

.info {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12px, auto));
  gap: 5px;
  font-weight: 700;
  color: var(--medium-blue);
  font-size: 12px;
  line-height: 16px;
  min-height: 16px;
  white-space: nowrap;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  max-width: calc(100vw - 32px - 80px - 16px);
  max-height: 16px;
}
.info::-webkit-scrollbar {
  display: none;
}

.tags {
  display: flex;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  max-width: calc(100vw - 32px - 80px - 16px);
}

.tags > *:not(:last-child) {
  margin-right: 8px;
}

.tags::-webkit-scrollbar {
  display: none;
}

.text {
  font-size: 14px;
}
