.geoc {
  position: relative;
}

.geoc::after {
  position: absolute;
  content: "GPS tracking";
  top: 50%;
  transform: translateY(-50%);
  right: -85px;
  font-weight: 900;
  font-size: 14px;
}
