.pinStar > path {
  stroke: white;
  stroke-width: 2px;
}

.pinStar {
  z-index: 2;
  position: absolute;
  top: 2px;
  left: 22px;
}
