.title {
  margin-top: 5px;
  max-width: 91.466vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text {
  max-width: 91.466vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--medium-blue);
  font-size: 14px;
}

.img {
  width: 91.466vw;
  height: calc(0.52478 * 91.466vw);
  border-radius: 8px;
}

.imgWrap {
  position: relative;
}

.tags {
  position: absolute;
  top: 8px;
  right: 0px;
}
