.container {
  height: 100%;
  padding: 0 16px;
  font-size: 14px;
}

.container:after {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  margin-top: 16px;
}

.header {
  display: flex;
  align-items: center;
  font-family: "Geared Slab";
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-top: 20px;
  margin-bottom: 20px;
  color: var(--goboat-blue);
}

.header svg {
  margin-right: 23px;
}

.imgWrapper {
  margin: 24px 0;
}

.img {
  width: calc(100vw - 32px);
  height: calc((100vw - 32px) * 0.583090379);
  border-radius: 8px;
}

.videoText {
  margin-top: 8px;
  font-size: 12px;
  font-weight: 700;
  color: var(--medium-blue);
}

.text {
  margin: 8px 0;
  font-size: 14px;
  line-height: 16px;
}

.subhead {
  font-size: 25px;
  margin-top: 12px;
  margin-bottom: 8px;
}

.btnWrapper {
  margin: 24px 0;
}

.videoWrapper {
  width: 100%;
  height: 200px;
  border-radius: 8px;
  overflow: hidden;
  margin: 24px 0;
}
