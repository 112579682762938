.heading h1 {
  font-family: "Geared Slab";
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 25px;
  margin: 21px 16px 2px 16px;
}

.filter {
  position: absolute;
  z-index: 1;
  top: 68px;
  right: 16px;
}

.filter div {
  display: flex;
  align-items: center;
}

.taggedItem {
  padding: 16px;
}

.buttonWrapper {
  min-height: 13px;
}

.taggedItem:not(:nth-last-of-type(1)) {
  position: relative;
}

.taggedItem:not(:nth-last-of-type(1))::after {
  content: "";
  position: absolute;
  width: calc(100% - 32px);
  height: 1px;
  background-color: #e1e5ee;
  bottom: 8px;
  left: 0;
  right: 0;
  margin: auto;
}
