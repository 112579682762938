.header {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  padding-top: 30px;
  align-items: flex-end;
}

.title {
  font-family: "Geared Slab";
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 20px;
}

.showAll {
  color: var(--medium-blue);
  font-weight: 700;
  font-size: 14px;
  padding-bottom: 4px;
}

.items > a {
  margin-bottom: 8px;
}

.border {
  height: 1px;
  width: calc(100% - 32px);
  margin: 0 16px;
  margin-top: 6px;
  background-color: #e1e5ee;
}
