.actionbar {
  background-color: var(--light-blue);
  display: grid;
  align-items: center;
  box-shadow: 0px -4px 6px rgba(50, 50, 93, 0.15);
  grid-template-columns: 1fr 1fr 1fr;
  height: calc(54px + env(safe-area-inset-bottom));
  padding-bottom: env(safe-area-inset-bottom);
  position: fixed;
  z-index: 2;
  bottom: 0;
  left: 0;
  right: 0;
}

.item {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.name {
  text-transform: capitalize;
  font-size: 12px;
  width: min-content;
  margin: 0 auto;
  color: var(--medium-blue);
}

.active {
  color: var(--goboat-blue);
}

.iconWrapper {
  display: inline-block;
  margin: 0 auto;
}
