@font-face {
  font-family: "Geared Slab";
  font-display: swap;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  src: url("fonts/GearedSlab-Thin.woff2") format("woff2");
}

@font-face {
  font-family: "Lato Latin";
  font-display: swap;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  src: url("fonts/LatoLatin-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Lato Latin";
  font-display: swap;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  src: url("fonts/LatoLatin-Bold.woff2") format("woff2");
}

:root {
  --goboat-blue: #2e3d5c;
  --blue: #54a8e5;
  --medium-blue: #959dad;
  --light-blue: #f1f3f6;
  --light-grey: #f5f5f5;
  --yellow: #ffb500;
  --green: #77a846;
  --red: #e55457;
  --white: #fff;
  --pink: #de0078;
  --purple: #7146a8;
}

* {
  box-sizing: border-box;
  margin: 0;
}

a {
  text-decoration: none;
  color: var(--goboat-blue);
}

body,
button {
  font-family: "Lato Latin", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  color: var(--goboat-blue);
}

button {
  border: none;
  outline: none;
  background-color: unset;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
