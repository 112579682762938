.footer {
  width: 100%;
  padding: 32px 16px;
  margin-top: 23px;
  background-color: var(--light-grey);
}

.title {
  font-family: "Geared Slab";
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 16px;
  font-size: 20px;
}

.content {
  display: grid;
  font-size: 14px;
  font-weight: 700;
  gap: 8px;
}

.infoSection {
  display: flex;
}

.infoSection svg {
  height: 14px;
  margin-right: 10px;
}
