.container {
  margin-bottom: 20px;
}

.questionAnswer {
  border-bottom: 1px solid var(--medium-blue);
}

.row {
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  text-align: start;
}

.question {
  font-size: 14px;
}

.answer {
  font-size: 14px;
  color: var(--medium-blue);
  margin-bottom: 16px;
}
