.nav {
  background-color: var(--goboat-blue);
  color: var(--white);
  height: 52px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.15);
  position: fixed;
  z-index: 2;
  left: 0;
  right: 0;
  top: 0;
}

.goboat {
  font-family: "Geared Slab";
  font-size: 20px;
  padding-top: 6px;
}

.wrapper {
  display: flex;
}

.wrapper > div:not(:last-child) {
  margin-right: 10px;
}

.wind {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.wind svg,
.city svg {
  margin-right: 2px;
}

.city {
  font-weight: 700;
  color: var(--medium-blue);
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.degrees {
  font-family: "Geared Slab";
  font-size: 20px;
  padding-top: 4px;
  width: 30px;
  text-align: center;
}

.sun {
  text-align: center;
  width: 20px;
}
