body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  box-sizing: border-box;
}

.container {
  height: fit-content;
  height: -webkit-fill-available;
  padding: env(safe-area-inset-top, 0) env(safe-area-inset-right, 0)
    env(safe-area-inset-bottom, 0) env(safe-area-inset-left, 0);
}

.content {
  padding-top: 52px;
  padding-bottom: calc(54px + env(safe-area-inset-bottom));
}

.content::-webkit-scrollbar {
  display: none;
}

.content ol li {
  font-size: 14px;
}
