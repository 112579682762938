.container {
  height: 100%;
  padding: 0 16px;
}

.container:after {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  margin-top: 16px;
}

h1 {
  font-family: "Geared Slab";
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 25px;
}

.headingPosts {
  margin: 21px 16px 0 16px;
}

.quiz {
  padding: 16px;
}

.quiz .heading h1 {
  margin: 21px 0px 16px 0px;
}

.quiz p {
  font-size: 14px;
}

.safetyDesc {
  padding: 16px 16px 0 16px;
  font-size: 14px;
}
