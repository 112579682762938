.title {
  margin-top: 5px;
  max-width: 42.666vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text {
  max-width: 42.666vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--medium-blue);
  font-weight: 700;
  font-size: 14px;
  margin-top: 3px;
}

.img {
  width: 42.666vw;
  height: calc(0.5 * 42.666vw);
  border-radius: 8px;
}
