.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  border-radius: 4px;
  margin: 16px 0;
  font-weight: 700;
  font-size: 14px;
  color: var(--goboat-blue);
  width: 100%;
}

.button svg {
  margin-right: 4px;
}

.goboat-blue {
  background-color: var(--goboat-blue);
  color: white;
}

.blue {
  background-color: var(--blue);
}

.medium-blue {
  background-color: var(--medium-blue);
}

.light-blue {
  background-color: var(--light-blue);
}

.light-grey {
  background-color: var(--light-grey);
}

.yellow {
  background-color: var(--yellow);
}

.green {
  background-color: var(--green);
}

.red {
  background-color: var(--red);
}

.white {
  background-color: var(--white);
}

.pink {
  background-color: var(--pink);
}

.purple {
  background-color: var(--purple);
}

.readmore {
  margin-bottom: 16px;
  margin-top: 16px;
}

.readMoreButtonMap {
  margin-top: 6px;
}
