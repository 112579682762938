.itemWrapper {
  width: calc(100% - 32px);
  padding: 8px;
  padding-bottom: 0;
  margin: 16px;
  background-color: white;
  position: absolute;
  bottom: calc(54px + env(safe-area-inset-bottom));
  z-index: 10;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.15);
}

.closeButton {
  position: absolute;
  right: 8px;
  top: 8px;
  padding: 0;
  z-index: 2;
}

.closeButton svg {
  background-color: var(--light-blue);
  padding: 4px;
  border-radius: 50%;
}

@media (orientation: landscape) {
  .itemWrapper {
    max-width: 328px;
    left: 0;
    right: 0;
    margin: 16px auto;
  }
}
