.safetyItem {
  display: block;
  padding: 16px;
  padding-bottom: 0;
}

.header {
  display: flex;
  justify-content: space-between;
  padding-top: 9px;
  margin-bottom: 15px;
  align-items: flex-end;
}

.title {
  font-family: "Geared Slab";
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 20px;
}

.showAll {
  color: var(--medium-blue);
  font-weight: 700;
  font-size: 14px;
  padding-bottom: 4px;
}

.text {
  padding-top: 8px;
  font-size: 14px;
  line-height: 16px;
}

.video {
  width: calc(100vw - 32px);
  height: calc((100vw - 32px) * 0.584795);
  background-color: #aaa;
  margin-top: 16px;
  border-radius: 8px;
}

.videoText {
  margin-top: 8px;
  font-size: 12px;
  font-weight: 700;
  color: var(--medium-blue);
}

.border {
  margin: 0 auto;
  margin-top: 24px;
  width: 100%;
  border-bottom: 1px solid #e1e5ee;
}
