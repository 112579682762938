.container {
  background-color: var(--light-blue);
  padding: 24px;
  margin: 24px 0;
  font-size: 14px;
  font-weight: 700;
  border-radius: 8px;
}

.container > div {
  margin: 24px 0;
}

.container h2 {
  font-family: "Geared Slab";
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 20px;
  margin-bottom: 12px;
}

.flex {
  display: flex;
  margin-bottom: 6px;
}

.flex:not(:first-of-type) {
  align-items: center;
}

.flex svg {
  margin-right: 10px;
}

.flex svg g path {
  stroke: var(--goboat-blue);
}
