.slider {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  height: min-content;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.slider > a {
  scroll-snap-align: center;
  margin-right: 8px;
}

.slider::-webkit-scrollbar {
  display: none;
}
