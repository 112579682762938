.tag {
  display: flex;
  height: 24px;
  min-width: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  width: min-content;
  font-size: 12px;
  font-weight: 700;
  white-space: nowrap;
  margin-right: 8px;
}

.longItem {
  padding: 0 8px;
  min-width: fit-content;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content svg {
  margin-right: 5px;
}

.goboat-blue {
  background-color: var(--goboat-blue);
  color: white;
}

.blue {
  background-color: var(--blue);
  color: white;
}

.medium-blue {
  background-color: var(--medium-blue);
  color: white;
}

.light-blue {
  background-color: var(--light-blue);
}

.light-grey {
  background-color: var(--light-grey);
}

.yellow {
  background-color: var(--yellow);
  color: white;
}

.green {
  background-color: var(--green);
  color: white;
}

.red {
  background-color: var(--red);
  color: white;
}

.white {
  background-color: var(--white);
}

.pink {
  background-color: var(--pink);
  color: white;
}

.purple {
  background-color: var(--purple);
  color: white;
}

/* export type Colors =
| "goboat-blue"
| "blue"
| "medium-blue"
| "light-blue"
| "light-grey"
| "yellow"
| "green"
| "red"
| "white"
| "pink"
| "purple" */

/* --goboat-blue: #2e3d5c;
--blue: #54a8e5;
--medium-blue: #959dad;
--light-blue: #f1f3f6;
--light-grey: #f5f5f5;
--yellow: #ffb500;
--green: #77a846;
--red: #e55457;
--white: #fff;
--pink: #de0078;
--purple: #7146a8; */
