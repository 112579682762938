.imgWrapper {
  margin: 24px 0;
}

.img {
  width: calc(100vw - 32px);
  height: calc((100vw - 32px) * 0.583090379);
  border-radius: 8px;
}

.videoText {
  margin-top: 8px;
  font-size: 12px;
  font-weight: 700;
  color: var(--medium-blue);
}

.factbox {
  padding: 32px;
  background-color: var(--light-blue);
  border-radius: 8px;
  margin: 24px 0;
  font-size: 14px;
}
